import * as React from 'react'
import BackgroundSlider from 'gatsby-image-background-slider'

import Layout from '../components/Layout'
import { Button } from '../components/Button'
import {
  Text,
  Cards,
  Container,
  SplitContent,
  SplitSection,
  SplitFigure,
  ReadMore,
  GallerySection,
} from '../components/styles'
import styled, { css } from 'styled-components'
import { xPad, yPad } from '../utils/theme'
import Contact from '../components/Contact'

import Img from 'gatsby-image'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import Arrow from '../components/Arrow'
import CTA from '../components/CTA'
import { Flex, Box } from 'grid-styled'
import Form from '../components/Form'
import FAQs from '../components/FAQs'

import Hero from '../components/Hero'
import ContentCard from '../components/ContentCard'
import BackgroundSection from '../components/BackgroundSection'

import Testimonials from '../components/Testimonials'
import { allTestimonials } from './testimonials'
import YouTube from 'react-youtube'
import { ContactDetails } from './contact-us'
import GalleryContainer from '../components/GalleryContainer'
import GalleryImage from '../components/GalleryImage'

export const Video = styled(YouTube)`
  margin-top: 25px;
  width: 90vw;
  height: 50.75vw;
  max-height: 576px;
  max-width: 1024px;
  border-radius: 8px;
`

const Logos = styled.div`
  display: flex;
  background: #ffffff;
  box-shadow: 0 14px 20px 0 rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  width: 900px;
  height: 100px;
  justify-content: space-evenly;
  align-items: center;
  margin: -50px auto 0;
  z-index: 99;
  position: relative;

  @media (max-width: 900px) {
    width: 95%;
  }

  @media (max-width: 600px) {
    .gatsby-image-outer-wrapper:last-child {
      display: none;
    }
  }

  @media (max-width: 500px) {
    display: none;
  }

  .gatsby-image-outer-wrapper {
    width: 80px;
    height: auto;
  }
`

const Images = styled.div`
  display: flex;
  margin: 30px 0 50px;
  justify-content: center;
  img,
  .gatsby-image-outer-wrapper {
    width: 250px;
    height: auto;
  }

  @media (max-width: 450px) {
    flex-direction: column;
    align-items: center;
  }
`

export const VideoContainer = styled.section`
  width: 100%;
  overflow: hidden;
  margin: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (max-width: 900px) {
    margin-top: 1rem;
  }

  @media (max-width: 480px) {
    margin-top: 1rem;
  }

  section {
    width: 100vw;
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
    figure {
      width: calc(100vw / 8);
    }
  }

  h2 {
    font-weight: 500;
    color: ${(props) => props.theme.colors.dark};
  }

  article {
    margin-top: 30px;
    width: 80%;
    max-width: 840px;
    color: #333;
    text-align: left;

    a {
      text-decoration: underline;
    }

    i {
      font-size: 1.5em;
      font-style: normal;
      color: ${(props) => props.theme.colors.primary};
    }
  }
`

export const HomeHeader = styled.header`
  height: 600px;
  padding-top: 9rem;
  padding-left: 15rem;
  padding-right: 1.5rem;
  color: #fff;
  position: relative;
  min-height: 550px;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media (min-width: 900px) and (max-width: 1280px) {
    figure {
      .gatsby-image-wrapper {
        overflow: visible !important;
        img {
          height: 650px !important;
        }
      }
    }
  }

  span {
    font-size: 1.5rem;
    font-weight: 500;
    opacity: 0.85;
  }

  @media (max-width: 900px) {
    padding-left: 1rem;
    text-align: left;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 1rem;
    height: 650px;
    > div > button {
      display: none;
    }
    > div:nth-child(2) {
      margin-top: 4rem;
    }
    figure,
    .gatsby-image-wrapper {
      height: 650px;
    }
  }
  @media (max-width: 600px) {
    > div:nth-child(2) {
      margin-top: 4rem;
    }
  }
  @media (max-width: 480px) {
    h2 {
      font-size: 60px;
      line-height: 70px;
    }
    > div:nth-child(2) {
      margin-top: 1rem;
    }
  }

  figure {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    ${(props) =>
      props.slant &&
      css`
        transform: skewY(-6deg);
        transform-origin: left top 0px;
      `}
    overflow: hidden;

    .gatsby-image-outer-wrapper {
      position: absolute !important;
      width: 85vw;
      bottom: -50px;
      right: 0;
    }
  }

  > div {
    > h2,
    > p {
      text-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
    }
  }

  h2 {
    color: #fff;
    font-weight: 700;
    max-width: 700px;
    line-height: 90px;
    font-size: 80px;

    em {
      text-decoration: underline;
      font-weight: 700;
      font-style: normal;
    }
  }

  p {
    font-weight: 600;
    font-size: 26px;
    line-height: 130%;
    padding-right: 2rem;
    max-width: 600px;
    margin: 1.5rem 0 2rem;
    opacity: 0.95;

    @media (max-width: 900px) {
      padding-right: 0;
    }
  }

  @media (min-width: 600px) {
    padding-top: 11rem;
    /* padding-left: 5rem; */
  }

  @media (min-width: 940px) {
    padding-top: 12rem;
  }
`

export const FormContainer = styled.div`
  width: 450px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 8px 18px rgba(0, 0, 0, 0.1);

  > div {
    padding: 1.5rem 2rem !important;
    border-top: 0;
  }
  h2 {
    color: #333 !important;
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  @media (max-width: 900px) {
    margin-top: 2rem;
  }

  @media (max-width: 480px) {
    width: 105%;
  }
`
export const AlternatingContent = styled.section`
  overflow: hidden;
  padding: 50px 0;

  > h2 {
    text-align: center;
    font-size: 3rem;
    padding: 0 8px;

    @media (max-width: 500px) {
      font-size: 2.5rem;
    }
    i {
      font-style: normal;
      color: ${(props) => props.theme.colors.primary};
    }
  }
`

const StyledBackgroundSection = styled(BackgroundSection)`
  min-height: 600px;
  background: #000;
  img {
    opacity: 0.3 !important;
  }
  @media (max-width: 500px) {
    height: auto;
    padding-bottom: 3rem;
  }
`

export const AboutContact = (props) => <Contact {...props} />

export default ({ data }) => {
  return (
    <Layout header="light">
      <Helmet>
        <title>Queensland Sports Aviators - Exhilaration Aviation QLD</title>
        <meta
          name="description"
          content="QSA represents our Affiliated Sports Aviation Clubs. Our
          enduring mission is to enable pilots and passengers to safely
          enjoy exhilarating flight experiences."
        />
        <meta
          name="google-site-verification"
          content="YS3Xc-1Kl9T0nL1slsj7LMvkWbHQ6RGyvyfqAeZZhBM"
        />
      </Helmet>

      <HomeHeader style={{ height: 700 }}>
        {/* <figure>
          <Img fluid={data.background.childImageSharp.fluid} />
          <Img fluid={data.background2.childImageSharp.fluid} />
          <Img fluid={data.background3.childImageSharp.fluid} />
          <Img fluid={data.background4.childImageSharp.fluid} />
        </figure> */}
        <BackgroundSlider
          query={data}
          initDelay={4} // delay before the first transition (if left at 0, the first image will be skipped initially)
          transition={4} // transition duration between images
          duration={12}
        />
        <div>
          <h2>
            Exhilaration <br />
            Aviation
          </h2>
          <Text size="large">
            Pursue a flight experience with one of our local schools, clubs or
            instructors.
          </Text>
        </div>
      </HomeHeader>

      <VideoContainer>
        <h1>Learn to Fly</h1>

        <article>
          <p style={{ fontSize: '1.3rem' }}>
            We have a number of clubs ranging from the New South Wales border to
            Northern Queensland. Find a flight school, local club, or tandem
            experience wherever you are located!
          </p>

          <p>
            <i>Our mission</i>
          </p>
          <p>
            QSA represents the interests of our Affiliated Sports Aviation Clubs
            and their members with key Queensland state based stakeholders. Our
            enduring mission is to enable our pilots and passengers to safely
            enjoy exhilarating flight experiences, whilst providing a
            sustainable future for this to occur.
          </p>

          <p>
            <i>Aviation flight experiences</i>
          </p>

          <p>
            If you have an urge to fly but are unsure of where to begin, join us
            by starting off with a first-taste tandem flight. In a tandem
            flight, you will be seated with a professional pilot who controls
            the flight so you can simply relax and enjoy your time up in the
            air. It's the easiest way to get a first taste of what it feels like
            to fly.
          </p>
        </article>
      </VideoContainer>

      <AlternatingContent style={{ paddingTop: 0 }} id="designs">
        <ContentCard
          image={data.paragliding.childImageSharp}
          title="Paragliding"
          text="Feel the rush of emotions, sensations, and freedom with your own unique paragliding experience. Book a Tandem flight or glide on your own wing now! "
          buttons={[
            { text: 'clubs', to: '/clubs' },
            { text: 'instructors', to: '/flight-school-instructors' },
          ]}
        />
        <ContentCard
          image={data.hanggliding.childImageSharp}
          title="Hang Gliding"
          text="Meet us up in the air and experience the serenity of free flight on your first day out with a professional hang-gliding pilot."
          buttons={[
            { text: 'clubs', to: '/clubs' },
            { text: 'instructors', to: '/flight-school-instructors' },
          ]}
        />
        <ContentCard
          image={data.microlighting.childImageSharp}
          title="Microlighting"
          text="Pursue a local microlighting flight experience and enjoy the air in a new and exciting way. Tandems, flights and lessons available."
          buttons={[
            { text: 'clubs', to: '/clubs' },
            { text: 'instructors', to: '/flight-school-instructors' },
          ]}
        />
      </AlternatingContent>

      <Hero bgImage={data.blurredHero.childImageSharp} height="550px">
        <h3>Want your own unique flight experience?</h3>
        <p>Complete the enquiry form today to find out how we can help.</p>
        <Flex>
          <Link to="/contact-us">
            <Button color={'#fff'} small={true} outline={true}>
              Contact Us
            </Button>
          </Link>
        </Flex>
      </Hero>

      <Testimonials testimonials={allTestimonials} />

      <GallerySection>
        <h2>Flight Gallery</h2>

        <GalleryContainer>
          {data.gallery.edges.map(
            (image, i) =>
              image.node.childImageSharp && (
                <GalleryImage modal={image.node.childImageSharp.modal}>
                  <Img fixed={image.node.childImageSharp.fixed} />
                </GalleryImage>
              )
          )}
        </GalleryContainer>
      </GallerySection>

      <AboutContact style={{ paddingTop: 0 }} data={data} />
    </Layout>
  )
}

export const query = graphql`
  query HomePageQuery {
    backgrounds: allFile(filter: { relativeDirectory: { eq: "backgrounds" } }) {
      nodes {
        relativePath
        childImageSharp {
          fluid(maxWidth: 4000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }

    gallery: allFile(filter: { relativeDirectory: { eq: "gallery/flight" } }) {
      edges {
        node {
          childImageSharp {
            fixed(width: 400, height: 400, toFormat: JPG) {
              ...GatsbyImageSharpFixed
            }
            modal: fluid(maxWidth: 1200, quality: 98, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    whyUs: file(relativePath: { eq: "why-us-jp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shirts: allFile(filter: { relativeDirectory: { eq: "home-shirts" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 700, toFormat: PNG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    logos: allFile(filter: { relativeDirectory: { eq: "logos" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    paragliding: file(relativePath: { eq: "paragliding.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hanggliding: file(relativePath: { eq: "hang-gliding.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    microlighting: file(relativePath: { eq: "microlighting.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    drSellars: file(relativePath: { eq: "about-gfit.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    accessories: file(relativePath: { eq: "gallery/roof/iron.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90, toFormat: JPG) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blurredHero: file(relativePath: { eq: "background-section-dark.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
